import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/card.webp";
import heroImage from "../assets/Frame 3077 (1).webp";
import cardImage from "../assets/Frame 39895.webp";
import { ROUTES, sessionStorageRoutes } from "../component/Constant";

function CTA({ headline, subtitle, paragraph }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    sessionStorage.setItem(sessionStorageRoutes.lander, true);
  }, []);
  return (
    <>
      <div className="hero-container">
        <div className="headline-wrapper">{/* <h1>{headline}</h1> */}</div>
        <div className="sub-hero-section">
          <div className="hero-section-wrapper">
            <div class="hero-section-subtitle">
              <h2>{subtitle}</h2>
            </div>
            <div className="hero-section-wrapper-content">
              <div class="hero-section-para">
                <p>{paragraph}</p>
              </div>
              <div className="cta">
                <Link
                  id="cta-click"
                  to={{ pathname: ROUTES.income, search: generatorQuery.get() }}
                >
                  I Want My Card
                </Link>
                <span>37 Available Slots Left, Act Now!</span>
              </div>
              <div class="hero-image">
                <img src={heroImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-section-card-container">
        <div class="container">
          <div class="card-container">
            <div class="card-content">
              <div class="card-content-title">
                <h2>“Receiving the subsidy was like a breeze”</h2>
              </div>
              <div class="card-content-image">
                <img src={cardImage} />
              </div>
            </div>
            <div class="mainbg">
              <img src={card_in_hamd} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CTA;
